<template>
  <div :id="'echarts' + id" class="task-node-line-echarts" />
</template>

<script>
export default {
  name: 'TaskNodeLineEcharts',
  props: {
    id: {
      type: String,
      default: ''
    },
    cpuData: {
      type: Array,
      default: () => []
    },
    memoryData: {
      type: Array,
      default: () => []
    },
    xData: {
      type: Array,
      default: () => []
    }

  },
  data() {
    return {
      echatsOption: {
        tooltip: {
          trigger: 'axis'
          // formatter: (params) => {
          //   console.log(params)
          //   return
          // }
        },
        legend: {
          icon: 'circle',
          right: 0,
          top: '5%'
        },
        grid: {
          left: '3%',
          right: '6%',
          bottom: '3%',
          top: '20%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.xData,
          axisLabel: {
            color: '#909399',
            fontSize: 14
          }
        },
        yAxis: {
          type: 'value',
          name: '单位',
          nameTextStyle: {
            fontSize: 14
          },
          splitNumber: 5,
          max: 100,
          min: 0,
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          },
          axisLabel: {
            formatter: '{value} %',
            color: '#909399',
            fontSize: 14
          }
        },
        color: ['#165DFF', '#14C9C9'],
        series: [
          {
            name: 'cpu利用率',
            type: 'line',
            smooth: true,
            showSymbol: false,
            avoidLabelOverlap: true,
            triggerLineEvent: true,
            data: this.cpuData

          },
          {
            name: '内存利用率',
            type: 'line',
            smooth: true,
            showSymbol: false,
            avoidLabelOverlap: true,
            triggerLineEvent: true,
            data: this.memoryData
          }
        ]
      }
    }
  },
  computed: {
    option() {
      return { id: this.id, cpuData: this.cpuData, memoryData: this.memoryData, xData: this.xData }
    }
  },
  watch: {
    option: {
      deep: true,
      handler(val) {
        if (this.myEcharts) {
          this.echatsOption.series[0].data = val.cpuData
          this.echatsOption.series[1].data = val.memoryData
          this.echatsOption.xAxis.data = val.xData
          this.myEcharts.setOption(this.echatsOption)
        }
      }
    }
  },
  created() {
    Object.defineProperty(this, 'myEcharts', {
      value: null,
      writable: true,
      configurable: true
    })
  },
  mounted() {
    this.$nextTick(() => {
      this.initEcharts()
    })
  },
  // 组件销毁时销毁echarts实例
  beforeUnmount() {
    if (this.myEcharts) {
      this.myEcharts.dispose()
    }
  },
  methods: {
    initEcharts() {
      const echarts = require('echarts')
      this.myEcharts = echarts.init(document.getElementById('echarts' + this.id))
      this.myEcharts.setOption(this.echatsOption)
    }
  }

}
</script>

<style>
.task-node-line-echarts{
  width: 100%;
  height: 240px;
}
</style>
